import { Menu, Restaurant, getDefaultInstanceId } from '@wix/restaurants-client-logic';
import { IPaymentMethod } from '@wix/cashier-payments-widget';
import { RestaurantLocations } from '@wix/ambassador-restaurant-locations/http';
import { setRestaurantLocations } from '../state/session/session.actions';
import { RestaurantLocation } from '@wix/ambassador-restaurant-locations/types';
import { restaurantsOloUouServiceFeeFailureWeb } from '@wix/bi-logger-olo-client/v2';

export async function getOrganizationFull(organizationId: string) {
  const response = await fetch(`https://api.wixrestaurants.com/v2/organizations/${organizationId}/full`);
  const { restaurant, menu }: { restaurant: Restaurant; menu: Menu } = await response.json();

  return {
    restaurant,
    menu,
  };
}

export async function getOrganizationFullByMsid(msid: string, baseUrl: string) {
  const response = await fetch(
    `${baseUrl}/_serverless/restaurants-organization-service-msid/v1/organization-full/${msid}`,
  );
  if (response.ok) {
    const { restaurant, menu }: { restaurant: Restaurant; menu: Menu } = await response.json();
    return {
      restaurant,
      menu,
      locations: [],
    };
  }

  throw new Error(`Failed getting organization by MSID: ${msid}, ${baseUrl}`);
}

export async function getPaymentMethods(appDefId: string, instanceId: string, locale: string) {
  const accountId = `${appDefId}:${instanceId}`;
  const response = await fetch(
    `https://apps.wixrestaurants.com/cashier/_api/payment-services-web/payments/v2/accounts/${accountId}/payment-methods?locale=${locale}`,
  );

  if (response.ok) {
    const { paymentMethods }: { paymentMethods: IPaymentMethod[] } = await response.json();
    return paymentMethods;
  } else {
    return [];
  }
}

export async function getOrganizationFullByAppDefAndInstance(instanceId: string, appId: string, language: string) {
  let response = await fetch(
    `https://apps.wixrestaurants.com/organization/get/?instanceId=${instanceId}&appId=${appId}`,
  );
  if (response.ok) {
    let res = await response.json();
    if (!res.value) {
      const defaultInstanceId = getDefaultInstanceId(language);
      response = await fetch(
        `https://apps.wixrestaurants.com/organization/get/?instanceId=${defaultInstanceId}&appId=${appId}`,
      );
    }

    if (response.ok) {
      res = res.value ? res : await response.json();
      const { restaurant, menu }: { restaurant: Restaurant; menu: Menu } = res.value;
      return {
        restaurant,
        menu,
        locations: [],
        fromSite: res.fromSite,
      };
    }
  }

  throw new Error(`Failed getting organization by app and instance: ${instanceId}, ${appId}, ${language}`);
}

export async function submitSmsVerification(restaurantId: string, orderId: string = '', code: string) {
  const response = await fetch(
    `https://api.wixrestaurants.com/v2/organizations/${restaurantId}/orders/${orderId}/confirm?as=customer&token=${code}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return Boolean(response.ok);
}

export type PartialLocation = Pick<
  Restaurant,
  | 'currentLocationId'
  | 'deliveryInfos'
  | 'deliveriesInfo'
  | 'timezone'
  | 'locationName'
  | 'address'
  | 'locale'
  | 'openTimes'
  | 'orders'
  | 'currency'
> & {
  isDefault?: boolean;
};

export async function getLocationsSettings(
  signedInstance: string,
  restaurantId: string,
  baseUrl: string,
): Promise<PartialLocation[]> {
  const response = await fetch(
    `${baseUrl}/_serverless/dashboard-middleware-api/${restaurantId}/locations/settings?fields=currentLocationId,address,locationName,locale,deliveryInfos,deliveriesInfo,timezone,isDefault,openTimes,orders,currency`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: signedInstance,
      },
    },
  );

  if (response.ok) {
    const result = await response.json();
    return result;
  } else {
    return [];
  }
}

export const fetchRestLocations = async ({
  signedInstance,
  locations = [],
  currentLocationId = '',
  biLogger,
}: {
  signedInstance: string;
  locations?: PartialLocation[];
  currentLocationId?: string;
  biLogger?: any;
}): Promise<RestaurantLocation[]> => {
  try {
    const locationIds = locations.length
      ? locations.map((location) => location.currentLocationId || '')
      : [currentLocationId];
    const restLocationService = RestaurantLocations('/_api/restaurants').RestaurantLocationsService()({
      Authorization: signedInstance,
    });
    const { restaurantLocations = [] } = await restLocationService.listRestaurantLocations({
      locationIds: locationIds.filter((id) => id !== ''),
    });

    return restaurantLocations;
  } catch (e) {
    console.log('error in fetching rest location id');
    biLogger &&
      biLogger.report(
        restaurantsOloUouServiceFeeFailureWeb({
          requestName: 'listRestaurantLocations',
          error: JSON.stringify(e),
        } as any),
      );
    return [];
  }
};
